import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const featureSilderContent = [
    { id: 1, img: 'pirith.png', title: 'More Info', bTitle: 'Pirith Ceremony 2025' },
    { id: 2, img: 'qmeeting-sept.png', title: 'More Info', bTitle: 'Third Quaterly Meeting - September 2024' },
    { id: 3, img: 'pirith.png', title: 'More Info', bTitle: 'Pirith Ceremony 2024' },
    { id: 4, img: 'qmeeting-june.jpg', title: 'More Info', bTitle: 'Second Quaterly Meeting - June 2024' },
    { id: 5, img: 'expo.jpg', title: 'More Info', bTitle: 'Future Minds Expo 2024' },
    { id: 6, img: 'surya.jpg', title: 'More Info', bTitle: 'Surya Mangalya 2024' },
    { id: 7, img: 'iftar.jpg', title: 'More Info', bTitle: 'Iftar Celebration 2024' },
    { id: 8, img: 'qmeeting-march.jpg', title: 'More Info', bTitle: 'First Quaterly Meeting - March 2024' }
];

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2 } },
        { breakpoint: 767.98, settings: { arrows: false, slidesToShow: 1 } }
    ]
};

const FeatureSliderOne = () => {
    return (
        <Fragment>
            <Slider {...settings} className="feature-slider row">
                
                {featureSilderContent.map((event) => (
                    <div key={event.id} className="col-lg-4">
                        <div className="feature-item">
                            <Link className="back-bg" to={`/event/${event.id}`}>
                                <img className="w-100" src={`assets/img/events/${event.img}`} alt="img"/>
                            </Link>
                            <h5>
                                <Link to={`/event/${event.id}`}>{event.title}</Link>
                            </h5>
                            <h3>
                                <Link to={`/event/${event.id}`}>{event.bTitle}</Link>
                            </h3>
                        </div>
                    </div>
                ))}
            </Slider>
        </Fragment>
    );
};

export default FeatureSliderOne;
