import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const BlogFourContent = [
    {
        id: 1,
        img: 'pirith.png',
        title: 'Pirith Ceremony 2025',
        desc: `To commemorate the New Year and invoke blessings upon the President and employees of the Aviha Group of Companies, a Pirith chanting ceremony was held at the Group's Walukarama office premises on January 1, 2025.`,
        date: 'January 01, 2025',
        icon2: 'i bi-clock',
        dataAos: 'fade-right',
        dataDelay: '50',
        btnText:'Read More'
    },
    {
        id: 2,
        img: 'qmeeting-sept.png',
        title: 'Aviha Group (Pvt) Ltd Third Quarterly Meeting and Staff Get Together - September 2024',
        desc: 'The Aviha Group of Companies (Pvt) Ltd. celebrated its third-quarter accomplishments with grandeur and excitement during the Third Quarterly Meeting and Fiesta, held at the Spice Room, Shangri-La on 25th September 2024.',
        date: 'September 25, 2024',
        icon2: 'i bi-clock',
        dataAos: 'fade-left',
        dataDelay: '50',
        btnText:'Read More'
    },
    {
        id: 3,
        img: 'pirith.png',
        title: 'Pirith Ceremony 2024',
        desc: 'A Pirith ceremony was held at the Walukarama office to bless the Aviha Group and its subsidiaries.',
        date: 'July 24, 2024',
        icon2: 'i bi-clock',
        dataAos: 'fade-right',
        dataDelay: '100',
        btnText:'Read More'
    },
    {
        id: 4,
        img: 'qmeeting-june.jpg',
        title: 'Aviha Group (Pvt) Ltd Quarterly Meeting and Staff Get Together - June 2024',
        desc: `An unforgettable evening of camaraderie and celebration, where Aviha's team came together to honor achievements and share laughter.`,
        date: 'June 26, 2024',
        icon2: 'i bi-clock',
         dataAos: 'fade-left',
        dataDelay: '150',
        btnText:'Read More'
    },
    {
        id: 5,
        img: 'expo.jpg',
        title: 'Future Minds Expo 2024',
        desc: 'Welcome to the Future Minds Expo 2024, where we unlock opportunities and shape futures. Our mission is to empower careers and inspire success stories by connecting talent globally. Join us as we bridge dreams and build brighter futures together.',
        date: 'From: June 14, 2024 To: June 16, 2024',
        icon2: 'i bi-clock',
        dataAos: 'fade-right',
        dataDelay: '250',
        btnText:'Read More'
    },
    {
        id: 6,
        img: 'surya.jpg',
        title: 'Surya Mangalya 2024',
        desc: `Explore the festivities of Surya Mangalya 2024, a grand New Year event filled with joy, culture, and celebration. Join us as we welcome the dawn of a new year with traditional rituals, music, and dance performances, and an array of delightful culinary delights. Let's embrace the spirit of unity and happiness together!`,
        date: 'April 13, 2024',
        icon2: 'i bi-clock',
        dataAos: 'fade-left',
        dataDelay: '300',
        btnText:'Read More'
    },
    {
        id: 7,
        img: 'iftar.jpg',
        title: 'Iftar Celebration 2024',
        desc: 'Relive the heartwarming moments of the Iftar Celebration 2024, a delightful staff gathering.',
        date: 'April 8, 2024',
        icon2: 'i bi-clock',
        dataAos: 'fade-right',
        dataDelay: '350',
        btnText:'Read More'
    },
    {
        id: 8,
        img: 'qmeeting-march.jpg',
        title: 'Aviha Group (Pvt) Ltd Quarterly Meeting and Staff Get Together - March 2024',
        desc: `An unforgettable evening of camaraderie and celebration, where Aviha's team came together to honor achievements and share laughter`,
        date: 'March 25, 2024',
        icon2: 'i bi-clock',
        dataAos: 'fade-left',
        dataDelay: '400',
        btnText:'Read More'
    }
]

const Events = () => {
    return (
        <Fragment>
        {BlogFourContent.map((val, i) => (
            <div key={i} className="blog-style-two style-four mb-lg-45 mb-175" data-aos={val.dataAos} data-aos-delay={val.dataDelay}>
                <div className="row gx-4 gx-xxl-5">
                    <div className="col-lg-6">
                        <div className="blog-thumb">
                            <img className="w-100" src={`assets/img/events/${val.img}`} alt="img" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="blog-content">
                           
                            <h3>
                                <Link className="blog-title" to={`/event/${val.id}`}>{val.title}</Link>
                            </h3>
                            <div className="blog-meta">
                                <a href="#"><i className={val.icon2} /> {val.date}</a>
                            </div>
                            <p>{val.desc}</p>
                            <Link className="more-btn" to={`/event/${val.id}`}>{val.btnText}</Link>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </Fragment>
    )
}

export default Events