
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AOS from 'aos';
import "aos/dist/aos.css"
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './router/AppRouter';
import ScrollToTop from './components/ScrollToTop';




function App() {

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="keywords" content="Aviha Group of Companies, Colombo Bartender & Barista School, Colombo Caregiver and Health Science School, Jobdaddy.lk, DOCMART Pharmaceuticals" />
        <meta name="description"
          content="Aviha Group is one of Sri Lanka's leading holding companies, encompassing four world-class brands: Colombo Bartender & Barista School, Colombo Caregiver & Health Science School, Jobdaddy.lk and DOCMART Pharmaceuticals. " />
        <meta property="og:Avihagroup" content="Sinco" />
        <meta property="og:url" content="https://www.avihagroup.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Aviha Group of Companies" />
      </Helmet>
      <ScrollToTop />
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
